.dashed-box {
    background-image: repeating-linear-gradient(0deg, #333333, #333333 12px, transparent 12px, transparent 22px, #333333 22px), repeating-linear-gradient(90deg, #333333, #333333 12px, transparent 12px, transparent 22px, #333333 22px), repeating-linear-gradient(180deg, #333333, #333333 12px, transparent 12px, transparent 22px, #333333 22px), repeating-linear-gradient(270deg, #333333, #333333 12px, transparent 12px, transparent 22px, #333333 22px);
    background-size: 1px 100%, 100% 1px, 1px 100%, 100% 1px;
    background-position: 0 0, 0 0, 100% 0, 0 100%;
    background-repeat: no-repeat;
}

.text-center {
    text-align: center !important;
}
.ql-container {
    border: none !important;
}
.ql-editor { 
    height: 300px  !important;
}

.rounded-circle {
    border-radius: 50%;
}

.font-weight-bold {
    font-weight: bold;
}